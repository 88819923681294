<template>
  <section>
    <v-row>
        <v-col v-if="allSingleCompany.results">
            <h1 >
                {{allSingleCompany.results.fullname}}
            </h1>
            <router-link :to="'/dashboard/company/add/'+allSingleCompany.results.userHandle">Add Company Details</router-link>
        </v-col>
    </v-row>
  </section>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  name: "SingleCompany",
  computed: {
    ...mapGetters(["allSingleCompany"]),
  },
  methods: {
    ...mapActions(["getSingleCompany"]),
  },
  created() {
    this.getSingleCompany({id:this.$route.params.slug});
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variables.scss";</style
>>
